import { Field, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import FormSelect from 'ui-kit/form-select/form-select';
import TextSetValue from 'ui-kit/text/textSetValue';

import PharmacyLookupCard, { PharmacyLookupCardProps } from 'components/pharmacy-cards/pharmacy-lookup-card';

import { accountProfileAddressesSelector } from 'state/account/account.selectors';
import {
    PrescriptionPharmacyPayload,
    savePrescriptionPharmacy
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { addTransferPrescriptionSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { setLastPharmacySearchFormValues } from 'state/pharmacy/pharmacy.reducer';
import { pharmacyGroupByRoutine } from 'state/pharmacy/pharmacy.routines';
import { isSearchingPharmaciesSelector, pharmacySearchGroupResults } from 'state/pharmacy/pharmacy.selector';

import { stateOptions } from 'const/options';

import { PHARMACY_SEARCH_SCHEMA } from 'schema/pharmacy.schema';

import { PharmacySearchGroup, PharmacySearchItem, PharmacySearchProps } from 'types/pharmacy';

import { buildPharmacyAddressString } from 'util/string';

import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import { useGlobalLink } from 'hooks/useGlobalLink';

import PharmacyLookup from './pharmacy-lookup.component';
import PharmacySearchResultsGroupToggle from './pharmacy-search-results/pharmacy-search-results-group-toggle';

//
// --- Constants ---

const FORM_NAME = 'Pharmacy Search Form';
const EVENT_KEY_PREFIX = 'group-';
const DEFAULT_NUM_RESULTS_VISIBLE = 10;

//
export interface PharmacySearchFormProps {
    formik?: any;
    onSubmitSelectedPharmacy: (data: PrescriptionPharmacyPayload) => void;
}
// --- PharmacySearchForm Component ---

const PharmacySearchForm = ({ formik, onSubmitSelectedPharmacy }: PharmacySearchFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { incrementStep } = useAddTransferPrescription();

    const [isSearchResultsOpen, setIsSearchResultsOpen] = useState<boolean>(false);
    const [selectedPharmacy, setSelectedPharmacy] = useState<PharmacySearchItem>();
    const [errorMessage, setErrorMessage] = useState('');

    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);
    const isSearchingPharmacies = useSelector(isSearchingPharmaciesSelector);
    const pharmacyGroups = useSelector(pharmacySearchGroupResults);
    const profileAddresses = useSelector(accountProfileAddressesSelector);

    const defaultAddress = profileAddresses.find((address) => address.currentShipping);

    // initial form values
    const initialValues: PharmacySearchProps = useMemo(
        () => ({
            name: '',
            city: transferPrescriptionState?.PharmacyCity
                ? transferPrescriptionState.PharmacyCity
                : defaultAddress?.city || '',
            state: transferPrescriptionState?.PharmacyState
                ? transferPrescriptionState.PharmacyState
                : defaultAddress?.state || ''
        }),
        [transferPrescriptionState, defaultAddress]
    );

    const clearSelectedPharmacy = useCallback(() => {
        setSelectedPharmacy(undefined);
    }, []);

    const submitSelectedPharmacy: PharmacyLookupCardProps['onChange'] = useCallback(
        (pharmacy: any, newIsChecked: any) => {
            if (newIsChecked) {
                setSelectedPharmacy(pharmacy);

                const pharmacyPayload: PrescriptionPharmacyPayload = {
                    pharmacyName: pharmacy.GroupName,
                    pharmacyPhone: pharmacy.PhysicalLocationPhoneNumber,
                    pharmacyAddress: buildPharmacyAddressString(pharmacy)
                };

                dispatch(savePrescriptionPharmacy(pharmacyPayload));
                formik.setValues(pharmacyPayload);
                onSubmitSelectedPharmacy(pharmacyPayload);
            } else {
                setSelectedPharmacy(undefined);
            }
        },
        [dispatch, incrementStep, t, selectedPharmacy]
    );

    const handlePharmacySearch = (values: PharmacySearchProps) => {
        setErrorMessage('');
        clearSelectedPharmacy();
        globalLink.setLastFormField('');

        // API call for pharmacy lookup
        dispatch(
            pharmacyGroupByRoutine.trigger({
                groupBy: 'name',
                pharmacyName: values.name,
                city: values.city,
                state: values.state,
                onSuccess: (response: PharmacySearchGroup[]) => {
                    //Show error message not found if pharmacyLookupResult length is 0
                    if (!response || response.length === 0) {
                        setErrorMessage(
                            t('prescriptionPharmacyForm.pharmacySearch.error.notFound', {
                                pharmacyName: values.name,
                                city: values.city,
                                state: values.state
                            })
                        );
                    } else {
                        dispatch(setLastPharmacySearchFormValues(values));
                        setIsSearchResultsOpen(true);
                    }
                },
                onFailure: () => {
                    setErrorMessage(t('prescriptionPharmacyForm.pharmacySearch.error.failureMessage'));
                }
            })
        );
    };

    const globalLink = useGlobalLink();

    const handlePharmacyBlur = (form: any) => {
        // The field must be touched in order for the isControlled
        // error to display.
        globalLink.setLastFormField(t('prescriptionPharmacyForm.pharmacySearch.inputs.pharmacyName'));
        if (form.isControlled) {
            form.setFieldTouched('pharmacyName', true);
        }
    };

    const getIsSelectedPharmacy = useCallback(
        (pharmacy: PharmacySearchItem): boolean => !!selectedPharmacy && pharmacy.NCPDP === selectedPharmacy.NCPDP,
        [selectedPharmacy]
    );

    // setting the key will cause the accordion to automatically expand - set the key if there's only one group
    const defaultAccordionKey = (pharmacyGroups || []).length === 1 ? `${EVENT_KEY_PREFIX}0` : undefined;

    const [numberResultsVisible, setNumberResultsVisible] = useState<number>(DEFAULT_NUM_RESULTS_VISIBLE);

    const showMoreResults = useCallback(
        (numPharmacies: number) => {
            setNumberResultsVisible(Math.min(numPharmacies, numberResultsVisible + DEFAULT_NUM_RESULTS_VISIBLE));
        },
        [numberResultsVisible]
    );

    const showLessResults = useCallback(() => {
        setNumberResultsVisible(DEFAULT_NUM_RESULTS_VISIBLE);
    }, [numberResultsVisible]);

    const showAllResults = useCallback(
        (numPharmacies: number) => {
            setNumberResultsVisible(numPharmacies);
        },
        [numberResultsVisible]
    );

    const showMoreLabelText = (numPharmacies: number) => {
        return Math.min(DEFAULT_NUM_RESULTS_VISIBLE, numPharmacies - numberResultsVisible);
    };

    return (
        <>
            <Formik<PharmacySearchProps>
                enableReinitialize={true}
                onSubmit={handlePharmacySearch}
                validationSchema={PHARMACY_SEARCH_SCHEMA}
                initialValues={initialValues}
            >
                {(formik) => (
                    <Form
                        className="pharmacy-search-form"
                        id="pharmacy-search-form"
                        data-ga-form-name={FORM_NAME}
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                    >
                        <div className="pharmacy-search-form-fields">
                            <Field
                                name="name"
                                label={t('prescriptionPharmacyForm.pharmacySearch.inputs.pharmacyName')}
                                component={PharmacyLookup}
                                onChange={formik.handleChange('name')}
                                formError={''}
                                errors={
                                    formik.errors?.name
                                        ? formik.errors.name === 'invalid length'
                                            ? t('prescriptionPharmacyForm.pharmacySearch.error.invalidLength', {
                                                  label: t(
                                                      'prescriptionPharmacyForm.pharmacySearch.inputs.pharmacyName'
                                                  )
                                              })
                                            : t('forms.errorMessages.requiredField', {
                                                  label: t(
                                                      'prescriptionPharmacyForm.pharmacySearch.inputs.pharmacyName'
                                                  )
                                              })
                                        : undefined
                                }
                                touched={formik.touched.name}
                                value={formik.values.name}
                                onPharmacyBlur={handlePharmacyBlur}
                                pharmacyCity={formik.values.city}
                                pharmacyState={formik.values.state}
                            />
                            <TextSetValue
                                name="city"
                                label={t('prescriptionPharmacyForm.pharmacySearch.inputs.city')}
                                onChange={formik.handleChange('city')}
                                onBlur={(event) => {
                                    formik.setFieldValue(event.target.name, event.target.value.trim());
                                    formik.handleBlur('city');
                                }}
                                errors={
                                    formik.errors?.city
                                        ? formik.errors.city === 'invalid length'
                                            ? t('prescriptionPharmacyForm.pharmacySearch.error.invalidLength', {
                                                  label: t('prescriptionPharmacyForm.pharmacySearch.inputs.city')
                                              })
                                            : t('forms.errorMessages.requiredField', {
                                                  label: t('prescriptionPharmacyForm.pharmacySearch.inputs.city')
                                              })
                                        : undefined
                                }
                                touched={formik.touched.city}
                                value={formik.values.city}
                                onFocus={() =>
                                    globalLink.handleFieldFocus(
                                        t('prescriptionPharmacyForm.pharmacySearch.inputs.city')
                                    )
                                }
                            />
                            <Field
                                formikControlled
                                id="state"
                                name="state"
                                options={stateOptions}
                                component={FormSelect}
                                placeholder={t('prescriptionPharmacyForm.pharmacySearch.inputs.state')}
                                errors={
                                    formik.errors?.state
                                        ? t('forms.errorMessages.requiredField', {
                                              label: t('prescriptionPharmacyForm.pharmacySearch.inputs.state')
                                          })
                                        : undefined
                                }
                                touched={formik.touched.state}
                                value={formik.values.state}
                                onFocus={() =>
                                    globalLink.handleFieldFocus(
                                        t('prescriptionPharmacyForm.pharmacySearch.inputs.state')
                                    )
                                }
                            />
                            <Button
                                async
                                isBusy={isSearchingPharmacies}
                                onClick={formik.handleSubmit}
                                disabled={
                                    !formik.values.name ||
                                    !formik.values.city ||
                                    !formik.values.state ||
                                    isSearchingPharmacies
                                }
                                label={t('prescriptionPharmacyForm.pharmacySearch.search')}
                                type={'button'}
                                dataGAFormName={FORM_NAME}
                                className="no-min-width border-0 font-weight-bolder"
                            />
                        </div>
                        {isSearchResultsOpen ? (
                            <div className="pharmacy-search-form-results">
                                <div className="pharmacy-search-form-results-header">
                                    <div className="title">
                                        {t('prescriptionPharmacyForm.pharmacySearch.searchResults')}
                                    </div>
                                    <div className="count">
                                        {(pharmacyGroups || []).length}{' '}
                                        {t('prescriptionPharmacyForm.pharmacySearch.results')}
                                    </div>
                                </div>
                                <div className="pharmacy-search-form-results-cards">
                                    {(pharmacyGroups || []).map((pharmacyGroup: PharmacySearchGroup, index: number) => {
                                        if (pharmacyGroup.pharmacies.length === 1) {
                                            const pharmacy = pharmacyGroup.pharmacies[0];

                                            return (
                                                <PharmacyLookupCard
                                                    key={`pharmacy-lookup-${index}`}
                                                    pharmacy={pharmacy}
                                                    inGroup={false}
                                                    onChange={submitSelectedPharmacy}
                                                    isChecked={getIsSelectedPharmacy(pharmacy)}
                                                />
                                            );
                                        } else {
                                            return (
                                                <div>
                                                    <Accordion
                                                        defaultActiveKey={defaultAccordionKey}
                                                        key={`pharmacy-accordion-${index}`}
                                                    >
                                                        <div className="accordionItem border-pale-blue border rounded-sm mb-2">
                                                            <div className="accordionItem--header">
                                                                <PharmacySearchResultsGroupToggle
                                                                    label={pharmacyGroup.groupPharmacyName}
                                                                    extraInfo={t(
                                                                        'prescriptionPharmacyForm.pharmacySearch.multipleLocationsAvailable'
                                                                    )}
                                                                    eventKey={`group-${index}`}
                                                                />
                                                            </div>

                                                            <Accordion.Collapse
                                                                eventKey={`${EVENT_KEY_PREFIX}${index}`}
                                                            >
                                                                <div>
                                                                    {pharmacyGroup.pharmacies
                                                                        .slice(0, numberResultsVisible)
                                                                        .map(
                                                                            (
                                                                                pharmacy: PharmacySearchItem,
                                                                                storeIndex: number
                                                                            ) => (
                                                                                <PharmacyLookupCard
                                                                                    key={`pharmacy-lookup-${index}-${storeIndex}`}
                                                                                    pharmacy={pharmacy}
                                                                                    inGroup={true}
                                                                                    onChange={submitSelectedPharmacy}
                                                                                    isChecked={getIsSelectedPharmacy(
                                                                                        pharmacy
                                                                                    )}
                                                                                    index={storeIndex}
                                                                                />
                                                                            )
                                                                        )}
                                                                    {pharmacyGroup.pharmacies.length >
                                                                        DEFAULT_NUM_RESULTS_VISIBLE && (
                                                                        <div className="pharmacy-search-show-more-less-btn">
                                                                            {/* Do not show the Show more if all are visible */}
                                                                            {numberResultsVisible <
                                                                                pharmacyGroup.pharmacies.length && (
                                                                                <>
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            showMoreResults(
                                                                                                pharmacyGroup.pharmacies
                                                                                                    .length
                                                                                            )
                                                                                        }
                                                                                        label={t(
                                                                                            'prescriptionPharmacyForm.pharmacySearch.showMoreOf',
                                                                                            {
                                                                                                showMoreNumber:
                                                                                                    showMoreLabelText(
                                                                                                        pharmacyGroup
                                                                                                            .pharmacies
                                                                                                            .length
                                                                                                    ),
                                                                                                numberOfResults:
                                                                                                    pharmacyGroup
                                                                                                        .pharmacies
                                                                                                        .length
                                                                                            }
                                                                                        )}
                                                                                        type="button"
                                                                                        variant="link"
                                                                                        className="show-more-less-btn"
                                                                                    />
                                                                                    <span className="btn show-more-less-btn show-more-separator">
                                                                                        |
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                            {pharmacyGroup.pharmacies.length ===
                                                                            numberResultsVisible ? (
                                                                                <Button
                                                                                    onClick={showLessResults}
                                                                                    label={t(
                                                                                        'prescriptionPharmacyForm.pharmacySearch.showLess'
                                                                                    )}
                                                                                    type="button"
                                                                                    variant="link"
                                                                                    className="show-more-less-btn"
                                                                                />
                                                                            ) : (
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        showAllResults(
                                                                                            pharmacyGroup.pharmacies
                                                                                                .length
                                                                                        )
                                                                                    }
                                                                                    label={t(
                                                                                        'prescriptionPharmacyForm.pharmacySearch.showAll'
                                                                                    )}
                                                                                    type="button"
                                                                                    variant="link"
                                                                                    className="show-more-less-btn"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Accordion.Collapse>
                                                        </div>
                                                    </Accordion>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </Form>
                )}
            </Formik>

            {errorMessage ? <div className="text-center lead mb-5">{errorMessage}</div> : null}
        </>
    );
};

export default PharmacySearchForm;
