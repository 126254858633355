import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PharmacySearchItem } from 'types/pharmacy';

import { lowercaseAndCapitalize } from 'util/cart';
import { noop } from 'util/function';
import { buildPharmacyAddressString, formatPhoneNumber } from 'util/string';

import './parmacy-lookup-card.style.scss';

export interface PharmacyLookupCardProps {
    pharmacy: PharmacySearchItem;
    isChecked?: boolean;
    inGroup?: boolean;
    onChange?: (pharmacy: PharmacySearchItem, newIsChecked: boolean) => void;
    index?: number;
}

const PharmacyLookupCard = ({
    isChecked = false,
    inGroup = false,
    onChange = noop,
    pharmacy,
    index
}: PharmacyLookupCardProps) => {
    const { t } = useTranslation();

    const isFirstItem = index === 0;
    const addressString = useMemo(() => buildPharmacyAddressString(pharmacy), [pharmacy]);
    const classes = classNames('pharmacy-lookup-card px-3', {
        'rounded-sm border border-pale-blue py-3 mb-2': !inGroup,
        'py-2': inGroup && !isFirstItem,
        'pt-1 pb-2': inGroup && isFirstItem
    });

    return (
        <div className={classes}>
            <div className="py-2">
                <label className="d-flex mb-0 text-left cursor-pointer">
                    <input
                        type="radio"
                        name="option"
                        onChange={() => onChange(pharmacy, !isChecked)}
                        checked={isChecked}
                        className="mt-1"
                    />
                    <span className="pharmacy-lookup-card-content">
                        {!inGroup && (
                            <span className="font-weight-bolder">{lowercaseAndCapitalize(pharmacy.GroupName)}</span>
                        )}
                        <span>{lowercaseAndCapitalize(addressString)}</span>
                        <span>
                            {pharmacy.PhysicalLocationPhoneNumber &&
                                t('prescriptionPharmacyForm.pastPharmacies.card.telephone', {
                                    phoneNumber: formatPhoneNumber(pharmacy.PhysicalLocationPhoneNumber, 'parentheses')
                                })}
                        </span>
                    </span>
                </label>
            </div>
        </div>
    );
};

export default PharmacyLookupCard;
