import { InputHTMLAttributes, forwardRef, useImperativeHandle, useRef } from 'react';
import { noop } from 'util/function';
import classNames from 'classnames';

export interface ImageUploadProps {
    onImageChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
    showUploadButton?: boolean;
    uploadButtonLabel?: string;
    className?: string;
}

export interface ImageUploadRef {
    uploadClick: () => void;
}

const ImageUpload = forwardRef<ImageUploadRef, ImageUploadProps>((props, ref) => {
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const { onImageChange = noop, showUploadButton = false, uploadButtonLabel = 'Upload Photo', className } = props;
    const classes = classNames(className, 'btn btn-primary');

    useImperativeHandle(ref, () => ({
        uploadClick() {
            uploadClick();
        }
    }));

    const uploadClick = () => {
        inputFileRef.current?.click();
        return false;
    };
    return (
        <div>
            <div className="create-book-photo-picker">
                <div
                    className="create-book-image-input-container"
                    style={{
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <input
                        type="file"
                        name="photo"
                        className="create-book-photo-input d-none"
                        placeholder="select the book photo"
                        style={{
                            opacity: 0,
                            width: '100%',
                            height: '100%'
                        }}
                        accept="image/*"
                        onChange={onImageChange}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        ref={inputFileRef}
                    />
                </div>
                {!showUploadButton && (
                    <input
                        value={uploadButtonLabel}
                        type="button"
                        className={classes}
                        onClick={(e) => {
                            e.preventDefault();
                            uploadClick();
                        }}
                    />
                )}
            </div>
        </div>
    );
});

export default ImageUpload;
